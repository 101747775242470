import {isEmpty, every} from 'lodash/fp';
import * as R from 'ramda/src/index';
import SafetyLogos from 'assets/images/footer/safety-logos.png';
import InternationalSitesStore from 'components/internationalSites/store';
import {brandLogo} from 'helpers/commonHelper';

const iconsExist = (socialIcons) => {
  return socialIcons && !every(isEmpty, socialIcons);
};

export const copyrightMessage = (brand) => {
  const company = {
    airtickets: 'airtickets',
    tripsta: 'tripsta S.A.',
    travelplanet24: 'Ferryscanner Inc.',
    ferryscanner: 'Ferryscanner Mon. IKE'
  };
  return `All rights reserved by ${company[brand]}`;
};

const bankLogoPath = function (assetHost) {
  if (!R.isEmpty(assetHost)) {
    return `${assetHost}/assets/airtickets/payment/banklogos/`;
  }
  return '';
};

const getBankAccounts = function (bank, assetHost) {
  if (!bank || !bank.bankAccounts || bank.bankEnabled !== '1') {
    return [];
  }
  return bank.bankAccounts.map(function (bankAccount) {
    return {
      name: bankAccount.name,
      owner: bankAccount.owner || bank.owner,
      swift: bankAccount.swift,
      accountNumber: bankAccount.accountNumber,
      iban: bankAccount.iban,
      logo: `${bankLogoPath(assetHost)}${bankAccount.key}.svg`
    };
  });
};

const trustLogo = function (market, isAirtickets) {
  return isAirtickets && market === 'gr';
};

function createFooterStore(
  {
    isAirtickets,
    isTravelplanet24,
    isFerryscanner,
    isAffiliate,
    bankDeposit,
    menuFooter,
    contactCms,
    assetHost,
    socialIcons,
    brand,
    market,
    availableWings,
    footerTranslations
  }) {

  const {seo, newsFeed, followUs, payment, internationalSites} = footerTranslations;

  return {
    brand,
    contactCms,
    seo: {
      translations: seo
    },
    newsFeed: {
      translations: newsFeed
    },
    followUs: {
      isEnabled: isTravelplanet24 && iconsExist(socialIcons),
      icons: socialIcons,
      translations: followUs
    },
    payment: {
      paymentInfo: getBankAccounts(bankDeposit, assetHost),
      safetyLogosUrl: SafetyLogos,
      translations: payment
    },
    internationalSites: new InternationalSitesStore({
      translations: internationalSites,
      market,
      availableWings,
      isEnabled: isAirtickets
    }),
    copyright: {
      message: copyrightMessage(brand),
      year: new Date().getFullYear(),
      logo: brandLogo(brand, 'footer'),
      liknossIconEnabled: isTravelplanet24,
      eotIconEnabled: trustLogo(market, isAirtickets)
    },
    links: menuFooter,
    market,
    isAirtickets,
    isFerryscanner,
    isAffiliate
  };
}

export default {createFooterStore};
