import React from 'react';
import PropTypes from 'prop-types';

function FooterSeo({translations}) {
  return (
    <>
      {translations.text}
    </>
  );
}

FooterSeo.propTypes = {
  translations: PropTypes.shape({
    text: PropTypes.string.isRequired
  })
};

export default FooterSeo;
