import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import {observer} from 'mobx-react';
import LazyImage from 'helpers/LazyImage';

function wingDropDownButton(wing) {
  return (
    <div><LazyImage imgClass="mr-1" src={wing.flagIconUrl} /> {wing.text}</div>
  );
}

class InternationalSites extends React.Component {
  renderWings = () => {
    const {availableWings, switchToWing} = this.props.store;
    return (availableWings.map(function (wing) {
      return <MenuItem
        key={wing.market}
        onSelect={switchToWing}
        eventKey={wing}
        title={wing.market}
        className="dropdown-menu-item"
      >
        <LazyImage
          title=""
          alt=""
          imgClass="mr-2"
          src={wing.flagIconUrl}
        />
        {wing.text}
      </MenuItem>;
    }));
  };

  render() {
    const {currentWing, translations, toggleOnce} = this.props.store;
    return (
      <div className="mb-4">
        <h3 className="footer-title">{translations.text}</h3>
        <Dropdown id="dropdownAvailableWings"
          componentClass="div"
          onToggle={(event) => {
            this.props.store.onToggle(event);
          }}
        >
          <Dropdown.Toggle
            bsStyle="default"
            className="btn-block btn-icon btn-icon-flip text-left"
          >
            {wingDropDownButton(currentWing)}
            <span className="icon icon-triangle-sm-down" aria-hidden="true"></span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="scrollable-menu w-1-1">
            {toggleOnce && this.renderWings()}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

InternationalSites.propTypes = {
  store: PropTypes.shape({
    currentWing: PropTypes.object.isRequired,
    availableWings: PropTypes.array.isRequired,
    switchToWing: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    toggleOnce: PropTypes.bool.isRequired,
    translations: PropTypes.object.isRequired
  })
};

export default observer(InternationalSites);
