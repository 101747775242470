import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import LazyImage from 'helpers/LazyImage';
import {enabledCCards} from 'components/footer/creditCards';
import VisaVerified from 'assets/images/footer/visa-verified.svg';
import MastercardSecure from 'assets/images/footer/secure_mastercard.svg';
import thawte from 'assets/images/footer/thawte.svg';

const BankAccountDetails = ({translations, bankAccount}) => {
  const popoverHoverFocus = (
    <Popover id={bankAccount.name} title={bankAccount.name}>
      <ul className="list-striped text-small">
        <li>{translations.owner}:</li>
        <li>{bankAccount.owner}</li>
        <li>{translations.number}:</li>
        <li>{bankAccount.accountNumber}</li>
        <li>IBAN:</li>
        <li>{bankAccount.iban}</li>
        <li>SWIFT:</li>
        <li>{bankAccount.swift}</li>
      </ul>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger={['click']}
      rootClose
      placement="top"
      overlay={popoverHoverFocus}
    >
      <li>
        <LazyImage
          imgClass="footer-bank-logos"
          src={bankAccount.logo}
          alt={bankAccount.name}
          title={bankAccount.name}
        />
      </li>
    </OverlayTrigger>
  );
};

BankAccountDetails.propTypes = {
  translations: PropTypes.shape({
    owner: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired
  }),
  bankAccount: PropTypes.object
};

const FooterContent = ({paymentInfo, translations}) => (
  <div className="d-flex justify-content-between flex-wrap">
    <ul className="list-inline d-xs-none">
      {
        enabledCCards.map(({logo, label}) => (
          <li key={label}>
            <LazyImage
              imgClass="footer-cc-logos"
              src={logo}
              width="50"
              height="31"
              title={label}
              alt={label}
            />
          </li>
        ))
      }
      {
        paymentInfo.map((bankAccount) =>
          <BankAccountDetails
            key={bankAccount.name}
            bankAccount={bankAccount}
            translations={translations}
          />
        )
      }
    </ul>
    <ul className="list-inline ml-lg-auto">
      <li>
        <LazyImage
          imgClass="footer-secure-logos"
          src={VisaVerified}
          width="62"
          height="34"
          title="Visa Verified"
          alt="Visa Verified"
        />
      </li>
      <li>
        <LazyImage
          imgClass="footer-secure-logos"
          src={MastercardSecure}
          width="95"
          height="34"
          title="Mastercard Secure"
          alt="Mastercard Secure"
        />
      </li>
      <li>
        <LazyImage
          imgClass="footer-secure-logos"
          src={thawte}
          width="125"
          height="34"
          title="Thawte"
          alt="Thawte"
        />
      </li>
    </ul>
  </div>
);

FooterContent.propTypes = {
  translations: PropTypes.shape({
    owner: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired
  }),
  paymentInfo: PropTypes.array.isRequired,
  safetyLogosUrl: PropTypes.string.isRequired
};

export default FooterContent;
