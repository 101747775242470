import React from 'react';
import PropTypes from 'prop-types';
import LazyImage from 'helpers/LazyImage';
import eotIcon from 'assets/images/footer/eot.svg';
import liknossIcon from 'assets/images/footer/liknoss-logo.svg';

const Copyright = ({message, year, logo, eotIconEnabled, liknossIconEnabled}) =>
  <div className="grid d-flex align-items-center">
    <div className="grid-item-sm-auto order-sm-1 d-flex">
      {eotIconEnabled && <LazyImage imgClass="footer-copyright-logos" src={eotIcon} alt="eot" title="eot" />}
      {
        liknossIconEnabled && <LazyImage
          width="76"
          height="56"
          imgClass="footer-copyright-logos"
          src={liknossIcon}
          alt="liknoss"
          title="liknoss" />
      }
    </div>
    <div className="grid-item-sm">
      <LazyImage
        imgClass="d-block footer-logo"
        src={logo}
        alt="logo"
        title="logo"
        width="150"
        height="25"
      />
      {year} &copy; {message}
    </div>
  </div>;

Copyright.propTypes = {
  message: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  logo: PropTypes.string.isRequired,
  eotIconEnabled: PropTypes.bool.isRequired,
  liknossIconEnabled: PropTypes.bool.isRequired
};

export default Copyright;
