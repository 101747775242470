import store from './store';
import cookiePreferenceStore from 'components/CookiePreferences/CookiePreferencesStore';
import {openBeacon} from 'helpers/commonHelper';
import {isEsky} from 'ferries/helpers/common';
import {brand, bankDeposit, menuFooter, assetHost, socialIcons, market,
  availableWings, isAirtickets, isTravelplanet24, isFerryscanner,
  isAffiliate} from 'helpers/stableValues';
import {footerTranslations} from 'helpers/translations';

if (menuFooter.length > 0) {
  const menuFooterLength = menuFooter.length;
  const penultimate = menuFooter[menuFooterLength - 2].values;
  if (isAirtickets) {
    const lastItemOfPenultimate = penultimate[penultimate.length - 1];
    lastItemOfPenultimate.click = cookiePreferenceStore.toggleModal;
    delete lastItemOfPenultimate.url;
  }

  menuFooter?.[menuFooterLength - 1]?.values?.forEach((item, index)=> {
    if (item?.url === '#' && item.title) {
      if (index === 0) {
        item.click = openBeacon;
      }
      item.tooltip = item.title;
      delete item.url;
    }
  });

  const second = menuFooter?.[1];
  if (second && isEsky) {
    second.values = [second?.values?.[0]];
  }
}

const contactCms = menuFooter.length > 0 ? menuFooter.pop() : {};

export default store.createFooterStore({
  isAirtickets, isTravelplanet24, isFerryscanner, isAffiliate, bankDeposit,
  menuFooter, contactCms, assetHost, socialIcons, brand, market, availableWings,
  footerTranslations
});
