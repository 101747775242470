import React from 'react';
import PropTypes from 'prop-types';
import {renderItemLink} from './common';

const fsSocials = [
  {icon: 'logo-facebook', href: 'https://www.facebook.com/Ferryscanner'},
  {icon: 'logo-instagram', href: 'https://www.instagram.com/ferryscanner'},
  {icon: 'logo-linkedin', href: 'https://gr.linkedin.com/company/ferryscanner'},
  {icon: 'tiktok', href: ' https://www.tiktok.com/@ferryscanner'}
];

const ContactUs = ({title, values, isFerryscanner, isAffiliate}) => (
  <div className="grid-item-xl-3-10 grid-item-md text-md-center text-xl-left">
    <div className="footer-title">{title}</div>
    <ul className="footer-link-list d-sm-block">
      {values.map(renderItemLink)}
    </ul>
    {
      isFerryscanner && !isAffiliate &&
        <div className="mt-4">
          {
            fsSocials.map(({icon, href}) =>
              <a
                key={icon}
                className="text-white mr-4"
                href={href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={`icon icon-${icon} icon-24`} />
              </a>
            )
          }
        </div>
    }
  </div>
);

ContactUs.propTypes = {
  title: PropTypes.string,
  values: PropTypes.array,
  isFerryscanner: PropTypes.bool.isRequired,
  isAffiliate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ])
};

export default ContactUs;
