import React from 'react';
import PropTypes from 'prop-types';

function link(url, icon) {
  return (
    <a className="footer-social-link" href={url}>
      <i className={`icon icon-logo-${icon} icon-lg`} />
    </a>
  );
}

function FollowUs({icons}) {
  const {
    socialfacebook,
    socialtwitter,
    socialgoogleplus,
    sociallinkedin,
    socialyoutube,
    // socialpinterest,
    socialvkontakte,
    socialinstagram
  } = icons;
  return (
    <>
      {socialfacebook && link(`https://www.facebook.com/${socialfacebook}`, 'facebook-1')}
      {socialinstagram && link(`https://www.instagram.com/${socialinstagram}`, 'instagram-1')}
      {sociallinkedin && link(`https://www.linkedin.com/company/${sociallinkedin}?trk=company_name`, 'linkedin')}
      {socialtwitter && link(`https://www.twitter.com/${socialtwitter}`, 'twitter-1')}
      {socialgoogleplus && link(`https://plus.google.com/${socialgoogleplus}`, 'google-plus-1')}
      {socialyoutube && link(`https://www.youtube.com/user/${socialyoutube}`, 'youtube-1')}
      {socialvkontakte && link(`https://vk.com/${socialvkontakte}`, '')}
    </>
  );
}

FollowUs.propTypes = {
  icons: PropTypes.shape({
    socialfollowus: PropTypes.string,
    socialfacebook: PropTypes.string,
    socialtwitter: PropTypes.string,
    socialgoogleplus: PropTypes.string,
    sociallinkedin: PropTypes.string,
    socialyoutube: PropTypes.string,
    socialpinterest: PropTypes.string,
    socialvkontakte: PropTypes.string,
    socialinstagram: PropTypes.string
  })
};

export default FollowUs;
