import AD from 'assets/images/flags-icon/AD.png';
import AE from 'assets/images/flags-icon/AE.png';
import AF from 'assets/images/flags-icon/AF.png';
import AG from 'assets/images/flags-icon/AG.png';
import AI from 'assets/images/flags-icon/AI.png';
import AL from 'assets/images/flags-icon/AL.png';
import AM from 'assets/images/flags-icon/AM.png';
import AO from 'assets/images/flags-icon/AO.png';
import AR from 'assets/images/flags-icon/AR.png';
import AS from 'assets/images/flags-icon/AS.png';
import AT from 'assets/images/flags-icon/AT.png';
import AU from 'assets/images/flags-icon/AU.png';
import AW from 'assets/images/flags-icon/AW.png';
import AX from 'assets/images/flags-icon/AX.png';
import AZ from 'assets/images/flags-icon/AZ.png';
import BA from 'assets/images/flags-icon/BA.png';
import BB from 'assets/images/flags-icon/BB.png';
import BD from 'assets/images/flags-icon/BD.png';
import BE from 'assets/images/flags-icon/BE.png';
import BF from 'assets/images/flags-icon/BF.png';
import BG from 'assets/images/flags-icon/BG.png';
import BH from 'assets/images/flags-icon/BH.png';
import BI from 'assets/images/flags-icon/BI.png';
import BJ from 'assets/images/flags-icon/BJ.png';
import BL from 'assets/images/flags-icon/BL.png';
import BM from 'assets/images/flags-icon/BM.png';
import BN from 'assets/images/flags-icon/BN.png';
import BO from 'assets/images/flags-icon/BO.png';
import BQ from 'assets/images/flags-icon/BQ.png';
import BR from 'assets/images/flags-icon/BR.png';
import BS from 'assets/images/flags-icon/BS.png';
import BT from 'assets/images/flags-icon/BT.png';
import BV from 'assets/images/flags-icon/BV.png';
import BW from 'assets/images/flags-icon/BW.png';
import BY from 'assets/images/flags-icon/BY.png';
import BZ from 'assets/images/flags-icon/BZ.png';
import CA from 'assets/images/flags-icon/CA.png';
import CC from 'assets/images/flags-icon/CC.png';
import CD from 'assets/images/flags-icon/CD.png';
import CF from 'assets/images/flags-icon/CF.png';
import CG from 'assets/images/flags-icon/CG.png';
import CH from 'assets/images/flags-icon/CH.png';
import CI from 'assets/images/flags-icon/CI.png';
import CK from 'assets/images/flags-icon/CK.png';
import CL from 'assets/images/flags-icon/CL.png';
import CM from 'assets/images/flags-icon/CM.png';
import CN from 'assets/images/flags-icon/CN.png';
import CO from 'assets/images/flags-icon/CO.png';
import CR from 'assets/images/flags-icon/CR.png';
import CU from 'assets/images/flags-icon/CU.png';
import CV from 'assets/images/flags-icon/CV.png';
import CW from 'assets/images/flags-icon/CW.png';
import CX from 'assets/images/flags-icon/CX.png';
import CY from 'assets/images/flags-icon/CY.png';
import CZ from 'assets/images/flags-icon/CZ.png';
import DE from 'assets/images/flags-icon/DE.png';
import DJ from 'assets/images/flags-icon/DJ.png';
import DK from 'assets/images/flags-icon/DK.png';
import DM from 'assets/images/flags-icon/DM.png';
import DO from 'assets/images/flags-icon/DO.png';
import DZ from 'assets/images/flags-icon/DZ.png';
import EC from 'assets/images/flags-icon/EC.png';
import EE from 'assets/images/flags-icon/EE.png';
import EG from 'assets/images/flags-icon/EG.png';
import ER from 'assets/images/flags-icon/ER.png';
import ES from 'assets/images/flags-icon/ES.png';
import ET from 'assets/images/flags-icon/ET.png';
import FI from 'assets/images/flags-icon/FI.png';
import FJ from 'assets/images/flags-icon/FJ.png';
import FK from 'assets/images/flags-icon/FK.png';
import FM from 'assets/images/flags-icon/FM.png';
import FO from 'assets/images/flags-icon/FO.png';
import FR from 'assets/images/flags-icon/FR.png';
import GA from 'assets/images/flags-icon/GA.png';
import UK from 'assets/images/flags-icon/GB.png';
import GD from 'assets/images/flags-icon/GD.png';
import GE from 'assets/images/flags-icon/GE.png';
import GF from 'assets/images/flags-icon/GF.png';
import GG from 'assets/images/flags-icon/GG.png';
import GH from 'assets/images/flags-icon/GH.png';
import GI from 'assets/images/flags-icon/GI.png';
import GL from 'assets/images/flags-icon/GL.png';
import GM from 'assets/images/flags-icon/GM.png';
import GN from 'assets/images/flags-icon/GN.png';
import GP from 'assets/images/flags-icon/GP.png';
import GQ from 'assets/images/flags-icon/GQ.png';
import GR from 'assets/images/flags-icon/GR.png';
import GS from 'assets/images/flags-icon/GS.png';
import GT from 'assets/images/flags-icon/GT.png';
import GU from 'assets/images/flags-icon/GU.png';
import GW from 'assets/images/flags-icon/GW.png';
import GY from 'assets/images/flags-icon/GY.png';
import HK from 'assets/images/flags-icon/HK.png';
import HN from 'assets/images/flags-icon/HN.png';
import HR from 'assets/images/flags-icon/HR.png';
import HT from 'assets/images/flags-icon/HT.png';
import HU from 'assets/images/flags-icon/HU.png';
import ID from 'assets/images/flags-icon/ID.png';
import IE from 'assets/images/flags-icon/IE.png';
import IL from 'assets/images/flags-icon/IL.png';
import IM from 'assets/images/flags-icon/IM.png';
import IN from 'assets/images/flags-icon/IN.png';
import IO from 'assets/images/flags-icon/IO.png';
import IQ from 'assets/images/flags-icon/IQ.png';
import IR from 'assets/images/flags-icon/IR.png';
import IS from 'assets/images/flags-icon/IS.png';
import IT from 'assets/images/flags-icon/IT.png';
import JE from 'assets/images/flags-icon/JE.png';
import JM from 'assets/images/flags-icon/JM.png';
import JO from 'assets/images/flags-icon/JO.png';
import JP from 'assets/images/flags-icon/JP.png';
import KE from 'assets/images/flags-icon/KE.png';
import KG from 'assets/images/flags-icon/KG.png';
import KH from 'assets/images/flags-icon/KH.png';
import KI from 'assets/images/flags-icon/KI.png';
import KM from 'assets/images/flags-icon/KM.png';
import KN from 'assets/images/flags-icon/KN.png';
import KP from 'assets/images/flags-icon/KP.png';
import KR from 'assets/images/flags-icon/KR.png';
import KW from 'assets/images/flags-icon/KW.png';
import KY from 'assets/images/flags-icon/KY.png';
import KZ from 'assets/images/flags-icon/KZ.png';
import LA from 'assets/images/flags-icon/LA.png';
import LB from 'assets/images/flags-icon/LB.png';
import LC from 'assets/images/flags-icon/LC.png';
import LI from 'assets/images/flags-icon/LI.png';
import LK from 'assets/images/flags-icon/LK.png';
import LR from 'assets/images/flags-icon/LR.png';
import LS from 'assets/images/flags-icon/LS.png';
import LT from 'assets/images/flags-icon/LT.png';
import LU from 'assets/images/flags-icon/LU.png';
import LV from 'assets/images/flags-icon/LV.png';
import LY from 'assets/images/flags-icon/LY.png';
import MA from 'assets/images/flags-icon/MA.png';
import MC from 'assets/images/flags-icon/MC.png';
import MD from 'assets/images/flags-icon/MD.png';
import ME from 'assets/images/flags-icon/ME.png';
import MG from 'assets/images/flags-icon/MG.png';
import MH from 'assets/images/flags-icon/MH.png';
import MK from 'assets/images/flags-icon/MK.png';
import ML from 'assets/images/flags-icon/ML.png';
import MM from 'assets/images/flags-icon/MM.png';
import MN from 'assets/images/flags-icon/MN.png';
import MO from 'assets/images/flags-icon/MO.png';
import MP from 'assets/images/flags-icon/MP.png';
import MQ from 'assets/images/flags-icon/MQ.png';
import MR from 'assets/images/flags-icon/MR.png';
import MS from 'assets/images/flags-icon/MS.png';
import MT from 'assets/images/flags-icon/MT.png';
import MU from 'assets/images/flags-icon/MU.png';
import MV from 'assets/images/flags-icon/MV.png';
import MW from 'assets/images/flags-icon/MW.png';
import MX from 'assets/images/flags-icon/MX.png';
import MY from 'assets/images/flags-icon/MY.png';
import MZ from 'assets/images/flags-icon/MZ.png';
import NA from 'assets/images/flags-icon/NA.png';
import NC from 'assets/images/flags-icon/NC.png';
import NE from 'assets/images/flags-icon/NE.png';
import NF from 'assets/images/flags-icon/NF.png';
import NG from 'assets/images/flags-icon/NG.png';
import NI from 'assets/images/flags-icon/NI.png';
import NL from 'assets/images/flags-icon/NL.png';
import NO from 'assets/images/flags-icon/NO.png';
import NP from 'assets/images/flags-icon/NP.png';
import NR from 'assets/images/flags-icon/NR.png';
import NU from 'assets/images/flags-icon/NU.png';
import NZ from 'assets/images/flags-icon/NZ.png';
import OM from 'assets/images/flags-icon/OM.png';
import PA from 'assets/images/flags-icon/PA.png';
import PE from 'assets/images/flags-icon/PE.png';
import PF from 'assets/images/flags-icon/PF.png';
import PG from 'assets/images/flags-icon/PG.png';
import PH from 'assets/images/flags-icon/PH.png';
import PK from 'assets/images/flags-icon/PK.png';
import PL from 'assets/images/flags-icon/PL.png';
import PM from 'assets/images/flags-icon/PM.png';
import PN from 'assets/images/flags-icon/PN.png';
import PR from 'assets/images/flags-icon/PR.png';
import PS from 'assets/images/flags-icon/PS.png';
import PT from 'assets/images/flags-icon/PT.png';
import PW from 'assets/images/flags-icon/PW.png';
import PY from 'assets/images/flags-icon/PY.png';
import QA from 'assets/images/flags-icon/QA.png';
import RE from 'assets/images/flags-icon/RE.png';
import RO from 'assets/images/flags-icon/RO.png';
import RS from 'assets/images/flags-icon/RS.png';
import RU from 'assets/images/flags-icon/RU.png';
import RW from 'assets/images/flags-icon/RW.png';
import SA from 'assets/images/flags-icon/SA.png';
import SB from 'assets/images/flags-icon/SB.png';
import SC from 'assets/images/flags-icon/SC.png';
import SD from 'assets/images/flags-icon/SD.png';
import SE from 'assets/images/flags-icon/SE.png';
import SG from 'assets/images/flags-icon/SG.png';
import SH from 'assets/images/flags-icon/SH.png';
import SI from 'assets/images/flags-icon/SI.png';
import SK from 'assets/images/flags-icon/SK.png';
import SL from 'assets/images/flags-icon/SL.png';
import SM from 'assets/images/flags-icon/SM.png';
import SN from 'assets/images/flags-icon/SN.png';
import SO from 'assets/images/flags-icon/SO.png';
import SR from 'assets/images/flags-icon/SR.png';
import SS from 'assets/images/flags-icon/SS.png';
import ST from 'assets/images/flags-icon/ST.png';
import SV from 'assets/images/flags-icon/SV.png';
import SX from 'assets/images/flags-icon/SX.png';
import SY from 'assets/images/flags-icon/SY.png';
import SZ from 'assets/images/flags-icon/SZ.png';
import TC from 'assets/images/flags-icon/TC.png';
import TD from 'assets/images/flags-icon/TD.png';
import TF from 'assets/images/flags-icon/TF.png';
import TG from 'assets/images/flags-icon/TG.png';
import TH from 'assets/images/flags-icon/TH.png';
import TJ from 'assets/images/flags-icon/TJ.png';
import TK from 'assets/images/flags-icon/TK.png';
import TL from 'assets/images/flags-icon/TL.png';
import TM from 'assets/images/flags-icon/TM.png';
import TN from 'assets/images/flags-icon/TN.png';
import TO from 'assets/images/flags-icon/TO.png';
import TP from 'assets/images/flags-icon/TP.png';
import TR from 'assets/images/flags-icon/TR.png';
import TT from 'assets/images/flags-icon/TT.png';
import TV from 'assets/images/flags-icon/TV.png';
import TW from 'assets/images/flags-icon/TW.png';
import TZ from 'assets/images/flags-icon/TZ.png';
import UA from 'assets/images/flags-icon/UA.png';
import UG from 'assets/images/flags-icon/UG.png';
import UM from 'assets/images/flags-icon/UM.png';
import US from 'assets/images/flags-icon/US.png';
import UY from 'assets/images/flags-icon/UY.png';
import UZ from 'assets/images/flags-icon/UZ.png';
import VA from 'assets/images/flags-icon/VA.png';
import VC from 'assets/images/flags-icon/VC.png';
import VE from 'assets/images/flags-icon/VE.png';
import VG from 'assets/images/flags-icon/VG.png';
import VI from 'assets/images/flags-icon/VI.png';
import VN from 'assets/images/flags-icon/VN.png';
import VU from 'assets/images/flags-icon/VU.png';
import WF from 'assets/images/flags-icon/WF.png';
import WS from 'assets/images/flags-icon/WS.png';
import YE from 'assets/images/flags-icon/YE.png';
import YT from 'assets/images/flags-icon/YT.png';
import ZA from 'assets/images/flags-icon/ZA.png';
import ZM from 'assets/images/flags-icon/ZM.png';
import ZW from 'assets/images/flags-icon/ZW.png';

export default {
  AD,
  AE,
  AF,
  AG,
  AI,
  AL,
  AM,
  AO,
  AR,
  AS,
  AT,
  AU,
  AW,
  AX,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BF,
  BG,
  BH,
  BI,
  BJ,
  BL,
  BM,
  BN,
  BO,
  BQ,
  BR,
  BS,
  BT,
  BV,
  BW,
  BY,
  BZ,
  CA,
  CC,
  CD,
  CF,
  CG,
  CH,
  CI,
  CK,
  CL,
  CM,
  CN,
  CO,
  CR,
  CU,
  CV,
  CW,
  CX,
  CY,
  CZ,
  DE,
  DJ,
  DK,
  DM,
  DO,
  DZ,
  EC,
  EE,
  EG,
  ER,
  ES,
  ET,
  FI,
  FJ,
  FK,
  FM,
  FO,
  FR,
  GA,
  UK,
  GD,
  GE,
  GF,
  GG,
  GH,
  GI,
  GL,
  GM,
  GN,
  GP,
  GQ,
  GR,
  GS,
  GT,
  GU,
  GW,
  GY,
  HK,
  HN,
  HR,
  HT,
  HU,
  ID,
  IE,
  IL,
  IM,
  IN,
  IO,
  IQ,
  IR,
  IS,
  IT,
  JE,
  JM,
  JO,
  JP,
  KE,
  KG,
  KH,
  KI,
  KM,
  KN,
  KP,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LR,
  LS,
  LT,
  LU,
  LV,
  LY,
  MA,
  MC,
  MD,
  ME,
  MG,
  MH,
  MK,
  ML,
  MM,
  MN,
  MO,
  MP,
  MQ,
  MR,
  MS,
  MT,
  MU,
  MV,
  MW,
  MX,
  MY,
  MZ,
  NA,
  NC,
  NE,
  NF,
  NG,
  NI,
  NL,
  NO,
  NP,
  NR,
  NU,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PG,
  PH,
  PK,
  PL,
  PM,
  PN,
  PR,
  PS,
  PT,
  PW,
  PY,
  QA,
  RE,
  RO,
  RS,
  RU,
  RW,
  SA,
  SB,
  SC,
  SD,
  SE,
  SG,
  SH,
  SI,
  SK,
  SL,
  SM,
  SN,
  SO,
  SR,
  SS,
  ST,
  SV,
  SX,
  SY,
  SZ,
  TC,
  TD,
  TF,
  TG,
  TH,
  TJ,
  TK,
  TL,
  TM,
  TN,
  TO,
  TP,
  TR,
  TT,
  TV,
  TW,
  TZ,
  UA,
  UG,
  UM,
  US,
  UY,
  UZ,
  VA,
  VC,
  VE,
  VG,
  VI,
  VN,
  VU,
  WF,
  WS,
  YE,
  YT,
  ZA,
  ZM,
  ZW
};

