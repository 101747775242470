import {isCurrencyEuro} from 'ferries/helpers/common';
import visaElectron from 'assets/images/cc-logos/visa_electron.svg';
import visaDebit from 'assets/images/cc-logos/visa_debit.svg';
import visa from 'assets/images/cc-logos/visa.svg';
import solo from 'assets/images/cc-logos/solo.svg';
import mastercardDebit from 'assets/images/cc-logos/mastercard_debit.svg';
import mastercard from 'assets/images/cc-logos/mastercard.svg';
import maestro from 'assets/images/cc-logos/maestro.svg';
import laser from 'assets/images/cc-logos/laser.svg';
import jcb from 'assets/images/cc-logos/jcb.svg';
import euroline from 'assets/images/cc-logos/euroline.svg';
import discover from 'assets/images/cc-logos/discover.svg';
import diners from 'assets/images/cc-logos/diners.svg';
import dankort from 'assets/images/cc-logos/dankort.svg';
import carteBleue from 'assets/images/cc-logos/carte_bleue.svg';
import amex from 'assets/images/cc-logos/american_express.svg';

const ccLogos = [
  {logo: visaElectron, label: 'Visa Electron', enabled: false},
  {logo: visaDebit, label: 'Visa Debit', enabled: false},
  {logo: visa, label: 'Visa', enabled: true},
  {logo: solo, label: 'Solo', enabled: false},
  {logo: mastercardDebit, label: 'Mastercard Debit', enabled: false},
  {logo: mastercard, label: 'Mastercard', enabled: true},
  {logo: maestro, label: 'Maestro', enabled: true},
  {logo: laser, label: 'Laser', enabled: false},
  {logo: euroline, label: 'Euroline', enabled: false},
  {logo: discover, label: 'Discover', enabled: false},
  {logo: diners, label: 'Diners', enabled: isCurrencyEuro},
  {logo: dankort, label: 'Dankort', enabled: false},
  {logo: carteBleue, label: 'Carte Bleue', enabled: false},
  {logo: amex, label: 'Amex', enabled: isCurrencyEuro},
  {logo: jcb, label: 'JCB', enabled: true}
];

const enabledCCards = ccLogos.filter(({enabled}) => enabled);

export {enabledCCards, ccLogos};