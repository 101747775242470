import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {addDataLayer} from 'helpers/commonHelper';

class EventTrigger extends Component {
  componentDidMount = () => {
    const {event} = this.props;
    addDataLayer({event});
  };

  render() {
    const {className, id} = this.props;
    return (
      <div id={id} className={className} />
    );
  }
}

EventTrigger.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  event: PropTypes.string.isRequired
};

export default EventTrigger;
