import {action, extendObservable, decorate} from 'mobx';
import * as R from 'ramda/src/index';
import Flags from './flagIcons';

class InternationalSitesStore {
  constructor({translations, availableWings, market, isEnabled}) {
    const wings = availableWings.map((item) => (
      {
        ...item,
        text: item.title,
        value: `http://${item.link}`,
        flagIconUrl: Flags[item.market.toUpperCase()]
      }
    ))
      .sort((a, b) => (a.text.localeCompare(b.text)));

    extendObservable(this, {
      toggleOnce: false
    });

    const wingExists = R.find(R.propEq('market', market), wings);
    Object.assign(this, {
      isEnabled: isEnabled ? Boolean(wingExists) : false,
      availableWings: wings,
      currentWing: wingExists || wings[0] || {},
      translations
    });
  }

  onToggle = () => {
    this.toggleOnce = true;
  };

  switchToWing = (wing) => {
    window.location = wing.value;
  };
}

decorate(InternationalSitesStore, {
  onToggle: action
});

export default InternationalSitesStore;
