import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Collapse from 'react-bootstrap/lib/Collapse';
import {renderItemLink} from './common';

const RenderCategory = ({isTablet, title, values}, index) => {
  const [showMore, setShowMore] = useState(!isTablet);
  const toggleMore = () => setShowMore((showMore) => !showMore);

  useEffect(() => {
    setShowMore(!isTablet);
  }, [isTablet]);

  return (
    <>
      {isTablet && <hr className="w-1-1"/>}
      <div key={index} className="grid-item-md px-lg-2 text-left">
        {
          title &&
            <div className="footer-title d-flex justify-content-between align-items-center">
              <span>{title}</span>
              {isTablet && <i
                onClick={toggleMore}
                className={` icon-24 icon icon-e-${showMore ? 'delete' : 'add'}-1`}
                aria-hidden="true"
              />}
            </div>
        }
        {
          values &&
            <Collapse in={showMore}>
              <div>
                <ul className="footer-link-list">
                  {values.map(renderItemLink)}
                </ul>
              </div>
            </Collapse>
        }
      </div>
    </>
  );
};

RenderCategory.propTypes = {
  isTablet: PropTypes.bool.isRequired,
  values: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};

const FooterLinks = ({isTablet, links}) => {
  return (
    <div className="grid-item-xl-7-10">
      <hr className="d-none d-md-block d-xl-none"/>
      <div className="grid">
        {
          links.map(({title, values}, index) => (
            <RenderCategory key={index} isTablet={isTablet} title={title} values={values} />
          ))
        }
      </div>
    </div>
  );
};

FooterLinks.propTypes = {
  isTablet: PropTypes.bool.isRequired,
  links: PropTypes.array.isRequired
};

export default FooterLinks;
